.Polaris-DropZone-FileUpload__Button {
  display: none;
}
.Polaris-DisplayText--sizeExtraLarge {
  text-align: center;
}

.Polaris-Modal-Dialog__Modal {
  background-color: transparent;
  box-shadow: none;
}

.Polaris-Modal-CloseButton--withoutTitle {
  display: none;
}

.Polaris-ResourceList__EmptySearchResultWrapper {
  padding-bottom: 0px;
  padding-top: 0px;
}
.Polaris-EmptySearchResult__Image {
  width: 300px;
  height: 300px;
}
.Polaris-ButtonGroup {
  justify-content: center;
  flex-wrap: nowrap;
}
.Polaris-DataTable__Cell .Polaris-ButtonGroup {
  float: right;
}
.spinnerContainer {
  height: 200px;
  width: 100%;
  position: relative;
  display: table;
}

.vertical-center {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.Polaris-TopBar {
  background-color: rgba(41, 75, 128, 1);
}
.Polaris-TopBar-UserMenu__Name {
  color: white;
}
.Polaris-Modal-CloseButton {
  display: none;
}
.stickyHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  padding-top: 50px;
  z-index: 1;
}
.testClick {
  cursor: pointer;
  margin: -1.6rem;
  padding: 1.6rem;
}
.Polaris-DropZone--sizeExtraLarge {
  min-height: 8rem;
  max-height: 8rem;
}
.MuiPaginationItem-sizeLarge {
  font-size: 1.2rem !important;
}
.MuiPaginationItem-sizeLarge .MuiPaginationItem-icon {
  font-size: 2rem !important;
}
.MuiPagination-ul {
  display: inline-flex !important;
}
.Polaris-Link {
  color: gray;
}
.Polaris-PageActions {
  border-top: 0px;
}
.Polaris-ProgressBar__Indicator {
  background-color: #81d4fa;
}
.divRow {
  background-color: #dcedc8;
  padding: 0.6rem;
  border-radius: 0.4rem;
}
.divRow2 {
  background-color: #ffcdd2;
  padding: 0.6rem;
  border-radius: 0.4rem;
}
.apexcharts-menu-icon {
  display: none;
}
.apexcharts-text tspan {
  letter-spacing: 0.2rem;
}
.apexchartsapexchartxexample {
  width: 20px !important;
}
.css-ahj2mt-MuiTypography-root {
  font-size: 1.5rem !important;
}
.css-1f2kvjf-MuiFormControlLabel-root {
  margin-left: 0rem !important;
}
